import {Box, Button, Flex, Spinner, Text} from "theme-ui";
import React, {FC, useRef, useState} from "react";
import {Form, FormRef} from "@components/common/Form/Form";
import {InputFieldForm} from "@components/common/Form/InputFieldForm";
import {PhoneNumberFieldForm} from "@components/common/Form/PhoneNumberFieldForm";
import {TextareaFieldForm} from "@components/common/Form/TextareaFieldForm";
import {CheckboxFieldForm} from "@components/common/Form/CheckboxFieldForm";
import {sendBid} from "@lib/senBid";
import {toast} from "react-toastify";
import Link from "@components/common/Link";

interface ChildrenFields {
  name: string
  type: string
  title: string
  defaultChecked: boolean
  placeholder: string
  required: boolean
  errorMsg: string
}

interface WriteToUs {
  'builder-id': string
  attributes: any
  color: string
  btnVariant: string
  btnText: string
  apiUrl: string
  fields: {
    name: string
    type: string
    title: string
    variant: string
    placeholder: string
    required: boolean
    errorMsg: string
    gap: number,
    defaultChecked: boolean
    children: Array<ChildrenFields>
  }[]
}

export const WriteToUs:FC<WriteToUs> = (props) => {
  const {attributes: { key, ...otherAttr}, fields, color, btnVariant, btnText, apiUrl, "builder-id": builderId} = props
  const [isFetching, setIsFetching] = useState(false)
  const formRef = useRef<FormRef>(null)

  const request = async (data: any) => {
    setIsFetching(true)
    await sendBid(apiUrl, data, setIsFetching, formRef?.current?.reset)
  }

  const drawChildren = (children: Array<ChildrenFields>, name: string) => {
    return children && children.map((child, idx) => {
      switch (child.type){
        case 'text': case 'name': case 'email':
          return <InputFieldForm key={`child-${child.name}-${idx}`} name={child.name} variant={'forms.inputs.secondary'} sx={{WebkitTextFillColor: color, borderColor: color}} placeholder={child.placeholder} type={child.type} pb={3} mb={3} errorMsg={child.errorMsg} required={child.required} />
        case 'phone':
          return <PhoneNumberFieldForm key={`child-${child.name}-${idx}`} name={child.name} variant={'forms.inputs.secondary'} sx={{WebkitTextFillColor: color, borderColor: color}} placeholder={child.placeholder} pb={3} mb={3} errorMsg={child.errorMsg} required={child.required} />
        case 'textarea':
          return <TextareaFieldForm key={`child-${child.name}-${idx}`} name={child.name} variant={'text'} sx={{WebkitTextFillColor: color, borderColor: color}} mt={4} />
        case 'checkbox':
          return <CheckboxFieldForm key={`child-${child.name}-${idx}`} name={`${name}.${child.name}`} variant={'forms.checkbox.secondary'} defaultChecked={child.defaultChecked} title={child.title} required={child.required} />
      }
    })
  }

  const draw = fields && fields?.map?.((field, idx) => {
    switch (field.type){
      case 'text': case 'name': case 'email':
        return <InputFieldForm key={`field-${field.name}-${idx}`} name={field.name} variant={'forms.inputs.secondary'} sx={{WebkitTextFillColor: color, borderColor: color}} placeholder={field.placeholder} type={field.type} pb={3} mb={3} errorMsg={field.errorMsg} required={field.required} />
      case 'phone':
        return <PhoneNumberFieldForm key={`field-${field.name}-${idx}`} name={field.name} variant={'forms.inputs.secondary'} sx={{WebkitTextFillColor: color, borderColor: color}} placeholder={field.placeholder} pb={3} mb={3} errorMsg={field.errorMsg} required={field.required} />
      case 'textarea':
        return <TextareaFieldForm key={`field-${field.name}-${idx}`} name={field.name} variant={'text'} sx={{WebkitTextFillColor: color, borderColor: color}} mt={4} />
      case 'checkbox':
        return <CheckboxFieldForm key={`field-${field.name}-${idx}`} name={field.name} variant={'forms.checkbox.secondary'} defaultChecked={field.defaultChecked} title={field.title} required={field.required} />
      case 'inline':
        return (
            <React.Fragment key={`flex-${field.name}-${idx}`}>
              {field.title && <Box mb={3} mt={4}>{field.title}</Box>}
              <Flex sx={{gap: `${field.gap}px`}}>
                {drawChildren(field.children, field.name)}
              </Flex>
            </React.Fragment>
        )
      case 'button':
        return (
            <Button sx={{width: '100%', height: '53px'}} mt={3} variant={field.variant}>
              {isFetching ? <Spinner size={36} /> : field.title}
            </Button>
        )
      case 'agreement':
        return (
            <Flex py={3} sx={{maxWidth: '420px', margin: '0 auto'}}>
              <CheckboxFieldForm
                  key={`field-${field.name}-${idx}`}
                  name={field.name}
                  variant={'forms.checkbox.secondary'}
                  defaultChecked={true}
                  title={<Box>Я даю согласие на обработку персональных данных и соглашаюсь с <Link href={'/privacy'}>политикой конфиденциальности</Link></Box>}
              />
            </Flex>
        )

    }
  })

  const bindForm = {}

  return (
      <Box key={key} {...otherAttr}>
        <Form ref={formRef} onSubmit={request}>
          {draw}
          {/*<Button sx={{width: '100%', height: '53px'}} mt={3} variant={btnVariant}>*/}
          {/*  {isFetching ? <Spinner size={36} /> : btnText}*/}
          {/*</Button>*/}
        </Form>
      </Box>
  )
}